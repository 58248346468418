.delete-button {
    position: fixed;
    bottom: 20px; 
    right: 20px; 
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .delete-icon {
    width: 50px; 
    height: 50px; 
  }
  

.conversation-history {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 800px; 
    margin: 0 auto;
  }
  

  .conversation-record {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    margin-bottom: 16px;
    padding: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
 
  .timestamp {
    font-size: 14px;
    color: #666;
  }
  
 
  .user-message {
    font-weight: bold;
    margin-top: 8px;
  }
  
 
  .knightingale-response {
    margin-top: 8px;
  }
  
  
  .conversation-record:hover {
    background-color: #f9f9f9;
    transition: background-color 0.2s ease-in-out;
  }

  .conversation-list {
    overflow-y: auto;
    /* border: 1px solid #ccc; */
    padding: 10px;
 
  }
  

  .download-button {
    background: none; 
    border: none;
    padding: 0; 
    cursor: pointer;
    display: flex;
    align-items: center; 
}

.download-button:hover {
    opacity: 0.7; 
}

.download-icon {
    width: 50px; 
    height: 50;
    margin-right: 5px; 
}
  
.conversation-list{

}

.conversation-list::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

.conversation-list::-webkit-scrollbar-track {
  background: transparent;       
  border-radius: 20px; 
}

.conversation-list::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #afabab52;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid #dee7f500;  /* creates padding around scroll thumb */
}

.conversation-list::-webkit-scrollbar-thumb:hover {
  background-color: #fff;
}

@media (max-width: 767px) {
.conversation-list::-webkit-scrollbar {
  width: 5px;               /* width of the entire scrollbar */
}

.conversation-list::-webkit-scrollbar-track {
  background: transparent;       
  border-radius: 20px; 
}

.conversation-list::-webkit-scrollbar-thumb {
  background-color: #dee7f518;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid #dee7f518;  /* creates padding around scroll thumb */
}}