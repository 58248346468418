/* Add this to your CSS file */
.close-button {
    background-color: transparent;
  }
  
  .close-button img {
    width: 32px;
    opacity: 1;
    padding: 5px;
    border-radius: 10px;
  }
  
  .close-button:hover {
    opacity: 1;
    background: #0000001a;
  }

  

.popup-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background: #00000070;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup {
    display: flex;
    margin: auto;
    max-width: 400px;
    width: 100%;
    border: 1px solid #ccc;
    padding: 20px;
    background: white;
    border-radius: 10px;

}

.popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.close {
    align-self: flex-end;
    cursor: pointer;
    font-size: 20px;
}