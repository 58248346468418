.desk_home_video_wrapper_share {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.desk_home_video_container_share {
    height: 100%;
    width: 100%;
    width: 100%;
    position: absolute;
    height: 100%;
}

.share-container {
    /* max-width: ; */
}

.rosapark-logo-wrapper {
    max-width: 1300px;
    width: 100%;
    display: flex;

}

.share-container {
    text-align: center;
    /* max-width: 1200px; */
    width: 100%;
    position: relative;
    padding-bottom: 20px;
}

.title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: fixed;
    z-index: 10;
}

.title {
    font-weight: bold;
    color: #fff;
}

.main-title {}

.share-middle-container-wrapper {
    /* padding-top: 60px; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.share-icon {
    width: 140px;
    margin-left: 5px;
    vertical-align: middle;
    margin-top: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.date {
    font-size: 16px;
    color: #333;
    margin-top: 20px;
}


.conversation-list {
    height: calc(100vh - 165px);
    margin-bottom: 0px;
    margin-top: 2px;
    list-style: none;
    padding: 0;
    min-width: 200px;
    padding: 0;
    min-width: 200px;
    /* max-height: 600px; */
}

.message-container {
    /* border: 1px solid #ccc; */
    /* margin: 10px;
    padding: 10px; */
    text-align: center;
}

.message-icon {
    margin-right: 5px;
    vertical-align: middle;
}

.user-message .title,
.knightingale-message .title {

    margin-right: 5px;
}

.user-message,
.knightingale-message {
    /* margin-bottom: 15px; */
    text-align: left;
    font-weight: normal;
}

.user-message .message-icon,
.knightingale-message .message-icon {
    display: inline-block;
    width: 20px;
    text-align: center;
}

.response {
    margin: 0;
}

.message-icon {
    margin-right: 5px;
    vertical-align: middle;
    width: 20px;

}

.knightingale-message img {
    width: 33px !important;
    padding-bottom: 10px;
}

.knightingale-message .title {
    line-height: 28px;
    padding-bottom: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
}

.user-message .message-icon,
.knightingale-message .message-icon {
    display: inline-block;
    text-align: center;
}



@media (max-aspect-ratio: 177/100) {
    .share_video {
        height: 100% !important;
    }
}

/* For aspect ratio greater than 1.77 */
@media (min-aspect-ratio: 178/100) {
    .share_video {
        height: auto !important;
    }
}


.knightingale-message-details {
    display: flex;
    gap: 10px;
}

.user-message .message-icon img,
.knightingale-message .message-icon img {
    width: 100%;
}


.user-message-container {
    display: flex;
    justify-content: flex-end;
}

.knightingale-message-container {
    display: flex;
    justify-content: flex-start;
}

.bot-answer {
    max-width: 70ch;
    flex-direction: column;
    position: relative;
    margin-right: 50px;
    gap: 10px;
}

.client-question {
    max-width: 70ch;
}


.share-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    background: #f4f8fe;
    flex-direction: column;
    align-items: center;
}

.start-sharebtn-wrapper {
    /* display: none; */
}

.share_container {
    display: flex;
    width: 100%;
    height: 100%;
}

.play-button-container {
    width: 100%;
}

.share_knightigale_wrapper {
    width: 48%;
    /* max-height: 496px; */
}

.play-audio-button {
    background: transparent;
    border: none;
    padding-left: 0px;
}

.play-audio-button img {
    width: 90px !important;
    padding-bottom: 0px;
    height: 34px;
    cursor: pointer;
}

.share_knightigale_wrapper video {
    height: 100%;
    max-height: 100%;
    width: 100%;
    border-radius: 0px;
}

.share_knightingale_conversation {
    width: 42%;
    padding: 0px 15px;
    position: relative;
    z-index: 10000;

}

.types_option {
    /* margin-right: 10px; */
}

.type_option_container {
    /* margin-right: 10px; */
}

.type_option_inner {
    padding: 6px 10px;
}

.share_wrapper {
    display: flex;
}

.type_option_text {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    /* 19.2px */
}

.type_option_img img {
    width: 32px;
    height: 32px;
}

.type_option_inner {
    display: flex;
    gap: 8px;
    border-radius: 10px;
    min-width: 180px;
    justify-content: center;
}

.types_container {
    display: flex;
    justify-content: space-between;
}

.types_details {

    display: flex;
    justify-content: flex-start;
    flex-direction: column;

}

.types_details_date {
    display: flex;
    justify-content: flex-start;
    padding-left: 15px;
}


.types_details_title {
    color: #fff;
    text-align: left;
    font-family: "Roboto", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    padding: 0px 15px;
}

.types_details_date p {
    color: #ffffffb5;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin: 10px 0px;
}



.share_footer_btn {
    padding: 2px 10px 10px;
    background: linear-gradient();
    width: 100%;

}

.share_footer_btn_container {
    width: 100%;
}


.share_footer_btn a {
    display: flex;
    width: 100%;
    height: 65px;
    padding: 12px 15px;
    border-radius: 15px;
    background: linear-gradient(90deg, #a79788 0.89%, #5c4c3e 98.66%);
    text-decoration: none;
    border-radius: 10px;
    font-family: "roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.2px;
    align-items: center;
    color: #ffffff;
    word-wrap: break-word;
    text-align: center;
    justify-content: center;
    white-space: pre-wrap;
    overflow-wrap: anywhere;
}

.share_footer {
    display: flex;
    justify-content: flex-start;
}

.share_footer_left {
    width: 48%;
}

.conversation-balance-top {
    display: none;
}

@media (max-width: 767px) {
    .share_footer_btn a {
     
        font-size: 16px;
        line-height: 20px;}
    .desktop-container-chat-video {
        display: none;
    }

    .conversation-balance-top {
        display: block;
    }

    .share-icon {
        width: 60px;
        margin-left: 14px;
    }

    .share_footer_left {
        width: 0%;
    }

    .share-container {
        /* padding-top: 20px; */
    }

    .share_knightigale_wrapper {
        position: absolute;
        height: 100vh;
        width: 100%;
    }

    .share_knightingale_conversation {
        width: 100%;
        padding: 0px;
    }

    .types_container {
        flex-direction: column;
    }

    .types_details {
        display: flex;
        justify-content: center;
    }

    .types_details_date {
        justify-content: center;
    }

    .types_details_title {
        text-align: center;
    }


    .share_footer {
        position: absolute;
        bottom: 0;
        display: flex;
        width: 100%;
        justify-content: center;
    }
    .conversation-list {
        padding-right: 20px;
        padding-left: 20px;
    }

    .conversation-list {
        height: calc(100vh - 167px);
    }
}

@media (min-width: 767px) {
    .mobile-container-chat-video {
        display: none;
    }

    .conversation-list {
        height: calc(100%);
        padding-right: 20px;
    }

    .share_container {
        height: 100%;
    }

    .share_middle_wrapper {
        height: 90%;
    }

    .bot-answer::before {
        content: "";
        position: absolute;
        top: 50%;
        left: -40px;
        transform: translateY(-50%);
        border-width: 12px 20px;
        border-style: solid;
        border-color: transparent #00000099 transparent transparent;
    }

    .types_wrappper {
        padding-top: 20px;
    }

    .share_footer_btn_container {
        display: flex;
        width: 42%;
        justify-content: center;
        position: relative;
    }

    .types_option {
        margin-right: 10px;
    }
}