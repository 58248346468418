.desk_home_bg_video_wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.desk_home_bg_video_container {
    width: 100%;
    position: absolute;
    /* left: -25.5%; */
    height: 100%;
    /* top: 10%; */

}

.new-background-bg-video-container-chat-video {
    /* object-fit: cover; */
    display: flex;
    /* justify-content: center;
    align-items: center;
    color: white;
    text-align: center; */
    /* overflow: hidden; */
}



.central-greeting-down-arrow {
    bottom: 0;
    animation: loadingArrow 1.5s cubic-bezier(0.68, -0.55, 0.27, 1) forwards;
}





.app {
    opacity: 0;
    position: relative;
    animation: bubbleAnimation 1s cubic-bezier(0.68, -0.55, 0.27, 1) both;
    transform-origin: bottom center;
    will-change: transform;
    perspective: 800px;
}

.app.loaded {
    opacity: 1;
    top: 15px;
}

@keyframes bubbleAnimation {
    0% {
        transform: scale(0) translate3d(0, 0, 0);
        opacity: 0;
        bottom: -70px;
        filter: blur(0px);

    }

    /* 25% {
      transform: scale(0.5) translate3d(0, 0, 0);
      opacity: 0.4;
      filter: blur(1px);
    }
  
    50% {
      transform: scale(0.5) translate3d(0, 0, 0);
      opacity: 0.7;
      filter: blur(2px);
      z-index: 1000;

    }
  
    75% {
      transform: scale(0.9) translate3d(0, 0, 0);
      opacity: 0.9;
      filter: blur(1px);
      z-index: 1000;

    } */

    100% {
        transform: scale(1) translate3d(0, 0, 0);
        opacity: 1;
        bottom: -10px;
        filter: blur(0);
        /* Remove blur effect */
        z-index: 100;

    }
}


.thumbnail-overlay svg {
    height: 307px;
    width: 320px;
}



.thumbnail-overlay {
    position: absolute;
    top: 72px;
    left: 0;
    width: 319px;
    height: 340px;
    z-index: -10;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    transition: opacity 0.3s ease;
}

.thumbnail-overlay img {
    max-width: 100%;
    max-height: 100%;
}

.new-background-video-container:hover .thumbnail-overlay {
    opacity: 0;
    /* Hide the overlay on hover */
}

.start-central-navbar-backside-color {
    display: none;
}

.central-greeting-wrapper {
    position: relative;
    width: 100%;
    max-width: 610px;
    display: none;
    min-height: 126px;
}

.desk_home_video_wrapper {
    position: relative;
    height: 100%;
    width: 45%;
    display: flex;
    justify-content: flex-end;
}

.desk_home_video_container {
    height: 100%;
}

.start-central-greeting-wrapper .central-greeting-container-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
}

.start-central-greeting-wrapper {
    display: flex !important;
    /* margin-left: auto;
    margin-right: auto; */
}

.central-greeting-container {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    border-radius: 35px;
    width: 100%;
    background: #FFF;
    stroke-width: 1px;
    stroke: #D4E3F9;
    filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.08));
    padding: 10px 10px 14px;
    position: absolute;
    z-index: 10;
    gap: 2px;
    max-width: 495px;
    min-height: 125px;

}

.central-greeting-user-name,
.central-greeting-text {
    color: #1A3054;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    font-weight: 500;
}


.central-greeting-user-name {
    font-weight: 700;
}

.central-greeting-text {
    font-weight: 500;
    margin-top: 8px;
}

.central-greeting-user img {
    padding-bottom: 1px;
}

.central-greeting-img {
    width: 74px;
}

.central-greeting-question {
    display: flex;
    align-items: center;
    gap: 10px;
}

.central-column-btn-right {
    width: 130px;
    height: 30px;
}


.central-greeting-ques {
    color: #0a2b72;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 33px;

}

.central-greeting-question img {
    width: 22px;
}

.central-greeting-down-arrow {
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 15px solid transparent;
    border-top: 20px solid #fff;
    margin: 0 auto;
    position: absolute;
    left: -5px;
    transform: rotate(40deg);
    z-index: 100;

}

.new-background-video-container {
    position: relative;
    width: 100vw;
    min-height: 380px;
    height: 41vh;
    background: transparent !important;
    border-radius: 16px 0px 0px;


}

.desk_home_video_container {
    width: 100%;
    /* max-width: 500px; */
}

.new-background-video-container-chat-video {
    width: 100%;
    object-fit: cover;
    max-height: 700px;
    border-radius: 25px;
}

.main-three-colum-layout-container {
    position: relative;
}

.new-background-video-container-chat {
    width: 100%;
    height: 100%;
    background: transparent !important;
    border-radius: 16px 0px 0px;
    display: flex;
    position: relative;
    align-items: flex-start;
    justify-content: center;
}

.start_videoclass {}

.left-column .video-wrapper .new-background-video-container-chat video {
    height: 100%;
    /* bottom: 40px; */
    top: auto;
}

.main-three-colum-layout .mobile-navbar-items li a .user-kps {
    width: 110% !important;

}

.main-three-colum-layout .mobile-navbar-items .user-li {
    border-radius: 16px !important;
}


.main-three-colum-layout .mobile-navbar-items .menu-li {
    background: none;

}

.main-three-colum-layout .mobile-navbar-items li a .menu-icon {
    width: 90% !important;
    margin-left: 0px;
}

/* start page */
.start-left-column {
    /* display: none !important; */
}

.start-menu-li {
    border-radius: 25px !important;
}

.start-central-column {
    max-width: 1300px !important;
    border-radius: 12px !important;
    display: flex !important;
    display: flex !important;
    align-items: flex-end;
    justify-content: flex-end;
    background: transparent;
    flex-direction: row;
    padding-right: 18px;
    height: 100%;
    padding-bottom: calc(100vh - 750px);
}

.main-three-colum-layout-container {
    width: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
}


.main-three-colum-layout .start-right-column {
    position: fixed !important;
    background-color: transparent !important;
    background: #e4efff !important;

}

.start-cental-video-wrapper {
    /* position: absolute; */
    position: relative;
    align-items: center;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
    /* top: 20px; */
    z-index: 0;

}

.start-central-video-display {
    display: none;
}

.start-central-logo {
    margin: -18px 0px 10px;
    position: relative;
    z-index: 10000;

}

.start-video {
    width: 780px !important;
    margin-right: 0px;
    margin-top: 50px;
    height: 438px;
    /* max-width: 420px; */
}


.start-mobile-text {
    color: #1E56A6;
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    /* 118.519% */
}

.start-bg-color {
    /* background: #f4f8fe !important; */
}

.start-main-three-colum-layout {
    align-items: center;
    /* background: #f4f8fe !important; */
    justify-content: center !important;
}

.start-mobile-text-wrapper {
    display: block !important;
    position: relative;
    z-index: 0;
}

.start-mobile-text-hide {
    display: none;
}

.start-mobile-text {
    margin: 0px;
}

.start-nav-img {
    display: none;
}

.start-nav-li {
    background: none !important;
}

.start-mobile-navbar-items {
    background: transparent !important;
}

.start-mobile-text-container {}

.center-column {
    display: flex;
    padding-right: 18px;
    /* padding-top: 38px; */
}

.start-main-inner-container {
    box-shadow: none !important;
}


/* ++++++++++++++++++++++++++++++++++++++++++++++++ */

.greeting_form_wrapper {
    display: flex;
    width: 55%;
    flex-direction: column;
    align-items: flex-end;
}


.mobile_1v3_nav {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;

}

.toggle_menu {}

.toggle_menu_wrapper:hover {
    background: #0000001a;
}

.toggle_menu_wrapper {
    width: 48px;
    padding: 10px;
    background: #0000000d;
    border-radius: 15px;
    display: flex;
    align-items: center;
    height: 48px;
    cursor: pointer;
}

.toggle_menu_desk {
    width: 30px;
    height: 30px;
    transform: scaleX(-1);
}

.mobile_1v3_nav_wrapper {
    padding: 12px;
    width: 100%;


}

.mobile_1v3_nav_profile {
    /* position: relative; */
    width: 40px;
}

.desk_home_video_profile {
    position: absolute;
    height: 100%;

}


.mobile_1v3_side_nav {
    position: fixed;
    top: 0px;
    height: 100%;
    /* padding: 10px; */
    z-index: 1000;
    width: 100%;
    left: calc(-100vw);
    transition: left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.start_mobile_1v3_side_nav {
    /* display: none; */
}

.side_nav_menu_item .sharebtn-wrapper {
    display: flex;
    justify-content: unset;
}

.mobile_1v3_side_nav_open {
    left: 0px;
    transition: left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.mobile_1v3_side_nav_container {
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: space-between;
}

.mobile_1v3_side_nav_profile a img {
    width: 70px;
    padding: 10px;
}

.side_nav_menu_container {
    width: 82vw;
    border-radius: 0px 20px 20px 0px;
    background: #ffffffcf;
    -webkit-backdrop-filter: blur(2px) brightness(100%);
    backdrop-filter: blur(10px) brightness(100%);
}

.side_nav_menu_item_back a img {
    padding-top: 24px;
    max-width: 75px;
    width: 32px;
}

.side_nav_menu_item_wrapper .side_nav_menu_item {
    margin-left: 5%;
    margin-right: 5%;
    padding: 10px;
    border-radius: 10px;
}

.side_nav_menu_item {
    display: flex;
    gap: 20px;
}

.side_nav_menu_item:hover {

    background: #00000015;
}

.side_nav_menu_item_text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Rubik', sans-serif;
    color: #0a2b72;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;

}

.side_nav_menu_item_wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 100%;
    justify-content: space-between;
}

.side_nav_menu_item_bottom_text {
    text-align: center;
    padding: 5px;
    align-items: center;
    display: flex;
    font-family: Roboto, sans-serif;
    font-size: 10px;
    font-weight: 400;
    color: #00000052;
    justify-content: center;
    margin-bottom: 5px;
}

.side_nav_menu_item_container {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.mobile_1v3_nav_profile img {
    width: 40px;
}

.side_nav_menu_item_back {
    display: flex;
    justify-content: flex-end;
}

.mobile_1v3_side_nav_wrapper {
    height: 100%;
}

.side_nav_menu_item_back a {
    justify-content: flex-end;
}

.side_nav_menu_item a {
    display: flex;
    gap: 20px;
    text-decoration: none;
    width: 100%;
}

.side_nav_menu_item_back {
    margin-left: 5%;
    margin-right: 5%;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    gap: 20px;
}

.left-column-logo a {
    display: flex;
    align-items: center;
}

.left-column-logo a .menu-logo-kps {}

.mobile_1v3_nav_toggle .toggle_menu_wrapper img {
    width: 32px;
    transform: scaleX(-1);
}

.mobile_1v3_nav_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.mobile_1v3_nav_profile a img {
    max-width: 40px;
}


.deskNav {
    /* display: none!important; */
    left: -82px !important;
    transition: left 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.left-column-logo {
    position: absolute;
    z-index: 100000;
    /* left: 27px; */
    top: 18px;
    display: flex;
    gap: 55px;
}


.left-column-vertical-buttons {
    position: absolute;
    z-index: 10000;
    left: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    transition: left 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.vertical-button {
    display: flex;
    justify-content: center;
}

.vertical-button-img {
    max-width: 50px;
    display: inline-block;
    position: relative;
    /* -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1);
    transition: all 100ms ease-in;
    transform: scale(1); */

}

.vertical-button-img:hover {
    z-index: 2;
    /* -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1.1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1.1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1.1);
    transition: all 100ms ease-in; */
    transform: scale(1.1);

}

.sharebtn .sharebtn-img {
    border: none;
    padding: 5px 6px 4px;
    border-radius: 10px;
    /* opacity: 0.75; */
    background: transparent;
}

.sharebtn .sharebtn-img:hover {
    opacity: 1;

}

.share {
    cursor: pointer;
}



.sharebtn-wrapper {
    display: flex;
    justify-content: center;

}


.sharebtn-inner {
    display: flex;
    position: relative;
    justify-content: center;
}

.side_nav_menu_item button {
    padding: 0px !important;
}

.vertical-button a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.side_nav_text {
    font-family: 'Rubik', sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    color: #000000;
    text-decoration: none;

}

.central-greeting-wrapper-mobile {
    width: 100%;
    display: flex;
    justify-content: center;
}

.central-greeting-wrapper-desktop {
    width: 100%;
    display: flex;
    justify-content: center;
}

.central-greeting-wrapper-mobile {
    display: none;
}

.start_central-greeting-wrapper-mobile {
    display: flex;
}

/* For aspect ratio less than 1.77 */
@media (max-aspect-ratio: 177/100) {
    .video_wrapper_dp {
        height: 100% !important;
    }
}

/* For aspect ratio greater than 1.77 */
@media (min-aspect-ratio: 178/100) {
    .video_wrapper_dp {
        height: auto !important;
    }
}


.right_side_nav {
    width: 100%;
}

.right_side_nav_inner img {
    display: none;
}

.mobile_1v3_nav_profile .user-kps {
    display: none;
}

.right_side_nav_inner {
    width: 100%;
    display: flex;
    justify-content: right;
    padding-right: 20px;
    cursor: pointer;
}

@media (min-width: 767px) {
    .left-column-logo {
        width: 100%;
        max-width: 1300px;
    }

    .desk_home_bg_video_wrapper {
        display: block;
    }

    .mobile_background_video {
        display: none !important;
    }

    .start-main-three-colum-layout,
    .start-central-column {
        min-height: 600px;

    }

    .new-background-video-container-chat {
        /* padding-bottom: 20px; */
    }



    .mobile_1v3_side_nav_open {
        display: none;
    }






    @keyframes loadingArrow {
        0% {
            bottom: 0;
        }

        100% {
            bottom: -4px;
        }
    }

}

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
.main-three-colum-layout .menu-logo-kps {
    max-width: 140px !important;
    position: absolute;
    top: 5px;
    padding-top: 0px;
}

.mobile_background_video {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 767px) {
    .video_wrapper_dp {
        height: 110% !important;
    }

    .desktop_background_video {
        display: none;
    }

    .mobile_background_video {
        display: block;
        /* top: 60px; */
        background-position-y: center;
        /* background-image: url(../../videos/chat/aspirations_edu_advisor_idle.mp4); */
    }

    .greeting_form_wrapper {
        width: 100%;
    }



    .start-central-column {
        flex-direction: column;
    }

    @keyframes loadingArrow {
        0% {
            bottom: 0;
        }

        100% {
            bottom: -14px;
        }
    }



    .start-cental-video-wrapper {
        /* top: 30px; */
    }

    .central-greeting-ques {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;

    }



    .new-background-video-container {
        display: flex;
        justify-content: center;
        width: 100vw;
        height: 355px;
    }

    .central-greeting-container {
        min-height: 74px;
        border-radius: 25px;
    }

    .central-greeting-wrapper {
        max-width: 350px;
        top: 10px !important;
        min-height: 74px;
        /* display: none!important; */
    }

    .central-greeting-text {
        font-size: 15px;
        font-weight: 500;
        line-height: 22.5px;
        text-align: left;
        margin-top: 5px;
    }



    .start-central-column {
        align-items: center;
    }

    .main-three-colum-layout .menu-logo-kps {
        width: 62px !important;
        max-width: 407px !important;
        left: 12px;
        top: 10px;

    }


    .mobile_1v3_nav_logo {
        display: flex;
        align-items: center;
    }

    .vertical-button-img {
        max-width: 35px;
    }

    .side_nav_menu_item .sharebtn-wrapper .sharebtn-inner {
        margin-left: 0px;
    }

    .knightingale_avatar_bar_wrapper {
        height: 60px;
    }

    .main-inner-container {
        background: #E4EFFF80 !important;
        box-shadow: none;
        /* backdrop-filter: blur(0); */

    }

    .start-main-background-container {
        height: 320px !important;
        /* top: -50px; */
        position: relative;
    }

    .start-mobile-text-wrapper {
        margin-bottom: 15px;
    }

    .start-main-logo {
        width: 250px;
    }

    .loading-indicator {
        /* margin-bottom: -18px !important; */

    }

    .central-navbar-backside-color {
        width: 100%;
        height: 56px;
        top: 0;
        background: red;
        position: absolute;

    }


    .greeting_form_wrapper {
        width: 100%;
    }
}

@media screen and (min-width: 767px) and (max-width: 992px) {
    .greeting_form_wrapper {
        width: 100%;
    }

    .start-central-video-display .video-wrapper .new-background-video-container video {
        max-width: 500px !important;
    }

    .new-background-video-container {
        display: flex;
        justify-content: center;
    }

    #yourVideoDivId video {
        object-fit: cover;
        max-width: 500px !important;

        margin-right: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        width: 100vw !important;
        top: -20px !important;
        height: unset;
        /* height: 355px; */
    }


}



@media (max-width: 992px) {
    .desk_home_bg_video_wrapper {
        /* display: none; */
    }

    .central-greeting-img {
        width: 52px;
    }

    .desk_home_video_wrapper {
        display: none;
    }

    .central-greeting-down-arrow {
        left: auto;
        transform: rotate(0deg);
    }
}



@media (min-width: 992px) {

    /* .start_central-greeting-wrapper-mobile {
        display: none;
    } */
    .desk_home_video_wrapper {
        display: none;
    }

    .central-greeting-img {
        width: 52px;
    }

    .central-greeting-img {
        width: 85px;
    }

    .app.loaded {
        top: -80px;
    }

    .central-greeting-wrapper-mobile {
        display: none !important;
    }

    .start-central-video-display {
        display: none !important;
    }

    .mobile-navbar-items {
        display: none !important;
    }

    .right-column {
        border-radius: 0px !important;

    }

    .mobile_1v3_nav {
        display: none;
    }
}

@media (max-width: 995px) {
    .mobile-navbar-items {
        /* background: #fff!important; */

    }

    .central-greeting-wrapper-desktop {
        display: none;
    }
}

@media (min-width: 992px) {
    .main-three-colum-layout .right-column {
        position: fixed !important;
        width: 100% !important;
        right: 0 !important;
        flex: 0 0 auto;
        top: 0px;
        bottom: auto !important;
        left: auto !important;
        display: flex;
        align-items: center;
        z-index: 999;

    }
}

.user-login-img {
    margin: auto 0 !important;
}

.main-three-colum-layout .navbar-items .user-login-img a img {
    width: 35px;
    /* margin-right: -45%; */
}



.right-column .navbar .navbar-items .menu-logo-kps-wrapper {
    height: 100% !important;
    padding: 0px !important;
    background: #fff !important;
    margin: 10px 0px 5px 0px !important;
}

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

.start-menu-logo-kps-wrapper {
    display: none;
}

.start-logo-profile {
    /* margin-top: 10px!important; */
}



.bold-text {
    font-weight: 800;
}


.right-column .navbar .navbar-items .start-menu-logo-wrapper {
    margin-left: 0px !important;
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
    .main-three-colum-layout .navbar-items {

        max-width: 1200px;
    }

    .user-login-img {
        /* width: 10% !important; */
    }
}

@media screen and (min-width: 1280px) {
    .output-area .q-and-a-container .main-q-a-box .bot-msg-bubble .answer-for-questions {
        max-width: 70ch !important;
    }

    .output-area .q-and-a-container .main-q-a-box .client-msg-bubble .client-questions {
        max-width: 70ch !important;
    }
}


.start-logo-profile-img {
    /* margin-right: -38% !important; */
}


.bacground-client-msg-bubble {
    margin-bottom: 0px !important;
}


.knightingale_avatar_bar img {
    width: 100%;
    position: relative;
    bottom: -4px;
    max-width: 55px;
    /* margin-top: 10px; */
}

.knightingale_avatar_bar_wrapper {
    height: 77px;
}

.knightingale_avatar_bar {
    height: 55px;
    display: flex;
    justify-content: left;
    /* padding-left: 20px; */
}
/* 
.cookie_bars{
    background: #cbbeaf;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
    align-items: center;
}
.cookie_bars button{
    background: #967859;
    border: none;
    border-radius: 5px;
    color: #fff;
    padding: 7px;
}

.cookie_bars p{
    margin: 8px;
    font-size: 11px;
    }

.message-container {
    background-color: #f8d7da;
    color: #721c24;
    padding: 5px;
    border: 1px solid #f5c6cb;
    border-radius: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .message-container p {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  
  .message-container button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .message-container button:hover {
    background-color: #0056b3;
  } */

  .showCookieMessage{
    height: calc(100% - 40px);

  }
  
  .hideCookieMessage{
    height: calc(100%);
  }

@media screen and (min-width: 767px) {
    .knightingale_avatar_bar {
        display: none;
    }
}


@media (max-width: 767px) {

    .knightingale_avatar_bar_wrapper {
        height: 60px;
    }
}