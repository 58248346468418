/* .message-container {
  background-color: #f8d7da;
  color: #721c24;
  padding: 5px;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.message-container p {
  margin-bottom: 0px;
  margin-top: 0px;
}

.message-container button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.message-container button:hover {
  background-color: #0056b3;
} */












.count-container{
  color: black;
}
.input-container-inner{
  width: 100%;
    min-height: 50px;
    display: flex;
    justify-content: center;
    padding-left: 5px;
    align-items: center;
    margin: auto;
    border-radius: 18px;
}

.count-wrapper{
  width: 100%;
  margin-bottom: 5px;
}

.sendtool:hover{
 filter: brightness(105%);
 -webkit-filter: brightness(105%);;
}

.count-container{
  padding-left: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  padding-bottom: 10px;
  text-align: left;
}
.sendtool {
    position: relative;
  }
  
  .sendtool .sendtooltext {
    text-wrap: nowrap;
    width: auto;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 7px;
    position: absolute;
    z-index: 1;
    bottom: 63px;
    left: -25px;
  }
  
  .sendtool .sendtooltext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
  
 
  

.select_img_wrapper {
  position: absolute;
  top: -110px;
  left: 0;
  width: 100%;
  border-radius: 10px;
  background: #ffff;
  max-height: 130px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.06), 0 1px 1px -2px rgba(0,0,0,.06), 0 1px 5px 0 rgba(0,0,0,.06);
}

.truncated-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.preview_detai_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select_img_inner {
  padding: 10px;
  width: 100%;
}

.select_img_inner p {
  color: #000;
  margin: 5px 0px;
}


.image_preview_close {
  /* position: absolute;
  top: 50px;
  right: 10px; */

}

.preview_details {
  display: flex;
  gap: 20px;
  align-items: center;
}

.image_preview_close_img {
  width: 20px;
  cursor: pointer;
  opacity: 0.5;
  margin-right: 20px;
}

.select_img_wrapper:hover .image_preview_close_img {
  opacity: 1;
}

.preview_title {
  color: #293041;
  font-weight: 500;
}
.preview_title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.listening-wrapper{
  color: #ec0808;
  position: absolute;
  top: 22px;
  z-index: 1000000;
  right: 80px;
  font-weight: 500;
}

.chat-logo-voice:hover{
  filter: brightness(105%);
  -webkit-filter: brightness(105%);
}


@media (max-width: 767px) {
  .image_preview_close_img {
  margin-right: 10px;
}

.image_preview_close {
  display: flex;
  align-items: center;
}


.count-container {
  padding-left: 10px;

  padding-bottom: 2px;
}


.input-container-inner {
  padding-left: 0px;
}
}